<template src="./reviewmoneristransaction.html"></template>
<style scoped src="./reviewmoneristransaction.css"></style>
<script>
import TransactionService from '@/services/TransactionService.vue'
import TransactionConstants from '@/constants/TransactionConstants.vue'
import Loading from 'vue-loading-overlay'
import xmlUtils from '@/utils/utils.vue'
import { required } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'
export default {
    components: {
        Loading, modal
    },
    data() {
        return {
            transactionId: '',
            resdata: {},
            loaderVisible: false,
            sequenceNumber: '',
            monerisAmount: '',
            approvalCode: '',
            responseCode: '',
            errorMessages: applicationConstants.formsMessages,
            showRecipientInformation: false,
            Utils: Utils,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            }
        }
    },
    async created() {
        try {
            this.transactionId = this.$route.params.Transactionid
            this.loaderVisible = true
            let reducer = (accumulator, currentValue) => {
                return (accumulator === currentValue?.Value) ? currentValue?.Text : accumulator
            }
            // get UserRefId
            // let getUserProfile = await AccountService.methods.GetAccountDetails(this.$route.params.email)
            // this.resdata.customerNumber = getUserProfile.csGetUserProfileResponse.UserRefId._text ? getUserProfile.csGetUserProfileResponse.UserRefId._text :''
            let transactionDetailsRespone = await TransactionService.methods.GetTransactionDeatils({ // get Transaction Details
                CustomerEmail: this.$route.params.email,
                TransactionId: this.$route.params.Transactionid
            })
            let transactionData = transactionDetailsRespone.csTransactionDetailsResponse.TransactionData
            if (transactionData && Object.keys(transactionData).length > 0) {
                if (transactionData.TransactionType._text === 'From Bank Account') {
                    if (transactionData.RemittanceData._text) {
                        let remittancedata = xmlUtils.methods.getParseObjectFromXml(transactionData.RemittanceData._text)
                        this.resdata.transactionPurpose = remittancedata.MoneyTransferRequestSavedData.Purpose._text ? TransactionConstants.transactionPurpose.reduce(reducer, remittancedata.MoneyTransferRequestSavedData.Purpose._text) : null
                        this.resdata.recipientId = remittancedata.MoneyTransferRequestSavedData.RecipientId._text
                        await TransactionService.methods.GetRecipientsDetails(this.$route.params.email, null, null) // Get Recipient Details
                            .then(res => {
                                let Recipients = res.csGetRecipientsResponse.RecipientItems?.RecipientItem
                                Recipients = Recipients ? Recipients : [] 
                                Recipients = Array.isArray(Recipients) ? Recipients : [Recipients]
                                let recipient = Recipients.find(item => item.Id._text === this.resdata.recipientId)
                                if (recipient) {
                                    this.showRecipientInformation = true
                                    this.resdata.country = recipient.Country._text ? recipient.Country._text : ''
                                    this.resdata.recipientName = recipient.FirstName?._text + ' ' + recipient.LastName?._text
                                    this.resdata.currency = recipient.RemittanceCurrency._text ? recipient.RemittanceCurrency._text : ''
                                    this.resdata.remittancedataGatewayId = recipient.RemittanceGatewayId._text ? recipient.RemittanceGatewayId._text : null
                                }
                            }).catch(error => {
                                this.alertObject = error
                                this.$bvModal.show(this.$refs.childModal.id)
                            })
                    }
                    this.resdata.clientId = transactionData.ClientStatus._text ? transactionData.ClientStatus._text : 'Not Available'
                    this.resdata.ProviderClientAppId = transactionData.ProviderClientAppId._text ? transactionData.ProviderClientAppId._text : 'Not Available'
                    this.resdata.ProviderIsoCode = transactionData.ProviderIsoCode._text ? transactionData.ProviderIsoCode._text : 'Not Available'
                    this.resdata.issConf = transactionData.ProviderIssConf?._text ? transactionData.ProviderIssConf?._text : 'Not Available'
                    this.resdata.issName = transactionData.ProviderIssName?._text ? transactionData.ProviderIssName?._text : 'Not Available'
                    this.resdata.ProviderMessage = transactionData.ProviderMessage._text ? transactionData.ProviderMessage._text : 'Not Available'
                    this.resdata.ProviderResult = transactionData.ProviderResult._text ? transactionData.ProviderResult._text : 'Not Available'
                    this.resdata.ProviderTransName = transactionData.ProviderTransName._text ? transactionData.ProviderTransName._text : 'Not Available'
                    this.resdata.ProviderTransactionTime = transactionData.ProviderTransactionTime._text ? transactionData.ProviderTransactionTime._text : 'Not Available'
                    this.resdata.ProviderTransactionkey = transactionData.ProviderTransactionkey._text ? transactionData.ProviderTransactionkey._text : 'Not Available'
                    this.resdata.ProviderTxnNum = transactionData.ProviderTxnNum._text ? transactionData.ProviderTxnNum._text : 'Not Available'
                }
                // this.resdata.requestId = transactionData.RequestId._text
                this.resdata.parentReferenceNumber = transactionData.ParentReferenceNumber._text ? transactionData.ParentReferenceNumber._text : ''
                this.resdata.dateAndTime = transactionData.DateTime._text ? Utils.methods.UTCtoLocal(moment(moment(transactionData.DateTime._text, 'MM/DD/YYYY HH:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss')) : ''
                this.resdata.destinationAmount = transactionData.DestAmount._text ? transactionData.DestAmount._text : ''
                this.resdata.requestedAmount = transactionData.RequestedAmount._text ? transactionData.RequestedAmount._text : ''
                this.resdata.serviceCharge = transactionData.ServiceCharge._text ? transactionData.ServiceCharge._text : ''
                this.resdata.sourceAmount = transactionData.SourceAmount._text ? transactionData.SourceAmount._text : ''
                this.resdata.phoneNumber = transactionData.PhoneNumber._text ? transactionData.PhoneNumber._text : ''
                this.resdata.transactionType = transactionData.TransactionType._text ? transactionData.TransactionType._text : ''
                this.resdata.currentTransactionStatus = transactionData.TransactionStatus._text ? TransactionConstants.transactionStatus.reduce(reducer, transactionData.TransactionStatus._text) : ''
                this.resdata.referenceNumber = transactionData.ReferenceNumber._text ? transactionData.ReferenceNumber._text : ''
            }
            this.loaderVisible = false
        } catch (error) {
            this.alertObject = error
            this.$bvModal.show(this.$refs.childModal.id)
            this.loaderVisible = false
        }
    },
    methods: {
        cancelMonerisReview() {
            this.$router.push({
                name: 'CompleteInteracMoneris',
                params: {
                    CompleteInteracMonerisParams: this.$route.params.CompleteInteracMonerisParams
                }
            })
        },
        async completeMonerisReview() {
            try {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                this.loaderVisible = true
                if (this.$route.params.email) {
                    let reqobj =
                    {
                        ManuallyVerified: "True",
                        ProviderAmount: this.monerisAmount,
                        ProviderBankApprovalCode: this.approvalCode,
                        ProviderBankTransactionId: this.sequenceNumber,
                        ProviderClientAppId: this.resdata.clientId,
                        ProviderResponseCode: this.responseCode,
                        // ProviderIsoCode:'',
                        ProviderIssConf: this.resdata.issConf,
                        ProviderIssName: this.resdata.issName,
                        //ProviderMessage: this.res.csTransactionDetailsResponse.TransactionData.ProviderMessage._text? this.res.csTransactionDetailsResponse.TransactionData.ProviderMessage._text : '',
                        //ProviderResponseCode: this.res.csTransactionDetailsResponse.TransactionData.ProviderResponseCode._text? this.res.csTransactionDetailsResponse.TransactionData.ProviderResponseCode._text : '',
                        // ProviderResult: this.res.csTransactionDetailsResponse.TransactionData.ProviderResult._text? this.res.csTransactionDetailsResponse.TransactionData.ProviderResult._text : '',
                        //ProviderTransName: this.res.csTransactionDetailsResponse.TransactionData.ProviderTransName._text? this.res.csTransactionDetailsResponse.TransactionData.ProviderTransName._text : '',
                        //ProviderTransactionTime: this.res.csTransactionDetailsResponse.TransactionData.ProviderTransactionTime._text? this.res.csTransactionDetailsResponse.TransactionData.ProviderTransactionTime._text : '',
                        //ProviderTransactionkey: this.res.csTransactionDetailsResponse.TransactionData.ProviderTransactionkey._text? this.res.csTransactionDetailsResponse.TransactionData.ProviderTransactionKey._text : '',
                        //ProviderTxnNum: this.res.csTransactionDetailsResponse.TransactionData.ProviderTxnNum._text? this.res.csTransactionDetailsResponse.TransactionData.ProviderTxnNum._text : '',
                        ReferenceNumber: this.resdata.referenceNumber
                    }
                    await TransactionService.methods.CompleteMonerisTransaction(reqobj)
                        .then(async res => {
                            await TransactionService.methods.AddTransactionNotes(this.$route.params.email + ' have completed in moneris transaction manually', this.resdata.referenceNumber)
                            if (res.csCompleteMonerisIOPTransactionResponse?.SendMoneyRequest) {
                                if (res.csCompleteMonerisIOPTransactionResponse?.PromoCodeData) {
                                    if (!this.resdata.RemittanceGatewayId) {
                                        this.resdata.remittancedataGatewayId = res.csCompleteMonerisIOPTransactionResponse.PromoCodeData.RemittanceGateway._text
                                    }
                                    TransactionService.methods.RedeemPromoCode({
                                        CustomerEmail: this.$route.params.email,
                                        Code: res.csCompleteMonerisIOPTransactionResponse.PromoCodeData.Code._text,
                                        IssueNumber: res.csCompleteMonerisIOPTransactionResponse.PromoCodeData.Issue._text,
                                        ParentReferenceNumber: this.resdata.parentReferenceNumber,
                                        ValidateOnly: 'false',
                                        RemittanceGateway: res.csCompleteMonerisIOPTransactionResponse.PromoCodeData.RemittanceGatewayId._text,
                                        Amount: this.resdata.RequestedAmount
                                    }).then(() => {
                                        this.sendMoneyAfterSaltTrasaction({
                                            OriginCurrency: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.OriginCurrency._text,
                                            RecipientID: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.RecipientId._text,
                                            XferAmount: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.XferAmount._text,
                                            CustomerEmail: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.CustomerEmail._text,
                                            MRMSSession: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.SessionId._text,
                                            Purpose: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.Purpose._text,
                                            ParentReferenceNumber: this.resdata.parentReferenceNumber,
                                            RequestedDestinationAmount: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.RequestedDestinationAmount._text
                                        })
                                    })
                                        .cath(err => {
                                            this.loaderVisible = false
                                            this.alertObject = err
                                            this.$bvModal.show(this.$refs.childModal.id)
                                            //Interac Compliance process went through successfully and amount credited in customer Account. While processing Promo Redemption following error occured:
                                        })
                                } else {
                                    this.loaderVisible = false
                                    this.sendMoneyAfterSaltTrasaction({
                                        OriginCurrency: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.OriginCurrency._text,
                                        RecipientID: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.RecipientId._text,
                                        XferAmount: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.XferAmount._text,
                                        CustomerEmail: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.CustomerEmail._text,
                                        MRMSSession: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.SessionId._text,
                                        Purpose: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.Purpose._text,
                                        ParentReferenceNumber: this.resdata.parentReferenceNumber,
                                        RequestedDestinationAmount: res.csCompleteMonerisIOPTransactionResponse.SendMoneyRequest.RequestedDestinationAmount._text
                                    })
                                }
                            } else {
                                this.loaderVisible = false
                                this.showAlert(null, 'Invalid Transaction Details. Not able to process Interac Compliance.')
                            }
                        })
                        .catch(error => {
                            this.loaderVisible = false
                            this.alertObject = error
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                }

            } catch (error) {
                this.loaderVisible = false
            }
        },
        async sendMoneyAfterSaltTrasaction(sendMoney) {
            //check isDisabledbank
            await TransactionService.methods.sendMoney(sendMoney)
                .then(res => {
                    //if success means redirect to Etransfer Confirmation page
                    this.$router.push({
                        name: 'Confirmation',
                        params: {
                            resSendMoney: res.csSendMoneyResponse.TransactionData,
                            parentReferenceNumber: res.csSendMoneyResponse.ParentReferenceNumber
                        }
                    })
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    validations: {
        sequenceNumber: { required },
        monerisAmount: { required },
        approvalCode: { required },
        responseCode: { required }
    }
}
</script>